import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { BalanceHistoryInterfacePaged } from '../../../types/balance_history.type';

import { Button, Tabs } from 'antd';
import PageHeader from '../../../components/Layout/PageHeader';
import BalanceHistoryTable from '../../../components/BalanceHistoryTable';

const { TabPane } = Tabs;
import './styles.css';
import { companiesActions } from '../../../actions/companies';
import { UserInterface } from '../../../types/user.type';

interface RootState {
  balance_history: {
    balance_history: BalanceHistoryInterfacePaged;
  }
  authentication: {
    user: UserInterface;
  }
}

const mapState = (state: RootState) => ({
  balance_history: state.balance_history.balance_history,
  user: state.authentication.user,
});

const actionCreators = {
  laodBalanceHistory: companiesActions.laodBalanceHistory,
  exportBalancehistory: companiesActions.exportBalancehistory
};
const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const BalanceHistoryPage = ({ 
  user,
  balance_history, 
  laodBalanceHistory,
  exportBalancehistory
}: PropsFromRedux) => {

  const getBalanceHistory = (page: number) => {
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      laodBalanceHistory(company_id, page);
    }
  }

  useEffect(() => {
    if (user.role === "admin") {
      console.log("opa")
    } else if(user && user.companies && user.companies.length > 0) {
      getBalanceHistory(1);
    }
  }, []);

  console.log(balance_history)
  
  const handleExportBalanceHistory= () => {
    debugger
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id as number
      (async () => {
        const file = await exportBalancehistory(company_id)
        const blob = new Blob([file], {type: "application/vnd.ms-excel"});
        const a = document.createElement('a')
        a.download = 'historico_saldo.xlsx'
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
      })()
    }
  }

  

  return (
    <>
      <PageHeader title={'Histórico de Saldo'} />
      <Tabs className='page-header-tab'>
        <TabPane>
          <div className='balance-table-content'>
          <Button className="clients-button" onClick={() => handleExportBalanceHistory()}>Exportar</Button>
            {balance_history &&
              <BalanceHistoryTable 
                balance_history={balance_history.data} 
                pagination={balance_history.pagination} 
                changePage={getBalanceHistory} 
                showBalance
              />
            }
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default connector(BalanceHistoryPage);
