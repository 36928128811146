import {useState} from 'react'

import { taxService } from '../../../services/tax.service';
import { TaxgroupsProps } from '../../../types/tax_group.type';

import PageHeader from "../../../components/Layout/PageHeader";
import CustomButton from '../../../components/Button';
import InputText from "../../../components/Inputs/Text";
import InputNumber from "../../../components/Inputs/Number";
import TaxTable from '../../../components/TaxTable';

import './styles.css'
import TabPane from 'antd/lib/tabs/TabPane';
import { Button, Tabs, message } from 'antd';

interface FormsProps {
    name?: string;
    taxGroups?: TaxgroupsProps[]
}

const TaxGroup = () => {
    const [forms, setForms] = useState<FormsProps>({
        name: '',
        taxGroups: [{
            installments: 0,
            brand_id: 1,
            value: 0,
            priority: 1
        },
        {
            installments: 1,
            brand_id: 1,
            value: 0,
            priority: 1
        
        }]
    })

    const [isLoadingActive, setIsLoadingActive] = useState<boolean>(false)

    const [installmentsList, setInstallmentsList] = useState<number[]>([0, 1])

    const handleSetTaxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        
        const taxGroups = forms?.taxGroups
        const isIntoTaxGroups = taxGroups?.filter(item => item.installments === Number(name))

        if (taxGroups && taxGroups?.length > 0 && isIntoTaxGroups && isIntoTaxGroups?.length > 0) {
            const newTaxGroup = taxGroups.map(item => {
                if (item.installments === Number(name)) {
                    item.value = Number(value)
                }
                return item
            })
            setForms({...forms, taxGroups: newTaxGroup})
        }  
    };

    const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setForms({...forms, name: value})
    }

    const handleDisableButton = () => {
        if (forms.name !== "" && (forms.taxGroups?.length ?? 0) >= 2) {
            return false
        }
        return true
    }

    const submit = async() => {
        setIsLoadingActive(true);
        try{
            if (forms.name && forms.name !== "" && forms.taxGroups && forms.taxGroups.length > 0) {
                const updatedTaxGroups = forms.taxGroups.map(item => ({
                    ...item,
                    brand: "" // Replace with the appropriate value for the brand property
                }));
                const response = await taxService.createTaxGroup({name: forms.name, taxes: updatedTaxGroups})
                if (response !== undefined && response !== null) {
                    window.location.reload();
                } else {
                    setIsLoadingActive(false);
                    message.error(`Falha ao modificar saldo. 1`);
                }
            }
        }
        catch (error) {
            setIsLoadingActive(false);
            message.error(`Falha ao modificar saldo 2.`);
        }
    }

    return (
        <>
            <PageHeader title="Grupo de taxas"/>
            <Tabs>
                <TabPane tab="Cadastrar Taxa" key="open">    
                <div className='button-div'>
                    <Button
                        onClick={() => {
                            setInstallmentsList([...installmentsList, installmentsList.length])
                            const newForms = {...forms};
                            newForms.taxGroups?.push({
                                installments: installmentsList.length,
                                brand_id: 1,
                                value: 0,
                                priority: 1
                            })
                            setForms(newForms)
                        }}
                        size='large'
                        style={{backgroundColor: '#46a2a5', borderColor: "#46a2a5", color: 'white', borderRadius: '5px'}}
                    >
                        Adicionar parcela
                    </Button>
                    <Button
                        onClick={() => {
                            const newInstallmentsList = installmentsList.slice(0, -1);
                            setInstallmentsList(newInstallmentsList);
                            const newForms = {...forms};
                            if (newForms.taxGroups) {
                                newForms.taxGroups = newForms.taxGroups.filter(item => newInstallmentsList.includes(item.installments))
                            }
                            setForms(newForms);
                        }}
                        size='large'
                        style={{backgroundColor: '#a54946', borderColor: '#a54946',  color: 'white', borderRadius: '5px'}}
                    >
                        Remover parcela
                    </Button>
                </div>
                    <div className="tax-group-container">
                        <div className="tax-group-content-name">
                            <InputText
                                placeholder='Digite o nome do grupo de taxa'
                                kind="primary"
                                onChange={handleSetName}
                            />
                        </div>
                        <div className="tax-group-content-installment">
                            {installmentsList.map((item, index) => {
                                const prefix = <span style={{paddingRight: '16px'}}>{item === 0 ? "débito": `${item}x`}</span>
                                return (
                                    <div key={index}>
                                        <InputNumber
                                            kind="primary"
                                            prefix={prefix}
                                            suffix={"%"}
                                            name={String(item)}
                                            value={`${forms.taxGroups?.filter(tax => tax.installments === item)[0].value.toString()}`}
                                            onChange={handleSetTaxValue}
                                            style={{width: "256px"}}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="tax-group-content-button">
                            <CustomButton
                                disabled={handleDisableButton()}
                                label='CADASTRAR NOVO GRUPO DE TAXA'
                                loading={isLoadingActive}
                                onClick={submit}
                            />
                        </div>
                    </div>
                    
                </TabPane>

                <TabPane tab="Taxas Cadastradas">
                    <TaxTable />
                </TabPane>
            </Tabs>

        </>
    )
}

export default TaxGroup;