import { AxiosRequestConfig } from 'axios';
import API from '../factory/api';
import {
  BS2ReceiptDetails,
  ReceiptWithdrawRequestInterface,
  WithdrawInterface,
  WithdrawRequestInterface,
} from '../types/withdraw.type';
import { BS2ReceiptInterface, ReceiptInterface } from '../types/receipt.type';
import moment from 'moment';

const createWithdraw = async (withdraw: WithdrawInterface) => {
  const config = {
    headers: {
      'Content-Type': `multipart/form-data}`,
    },
  };
  
  const formData = new FormData();
  formData.append('is_completed', String(withdraw.is_completed));
  formData.append('company_id', String(withdraw.company_id));
  formData.append('company_name', String(withdraw.company_name));
  formData.append('bankinformation_id', String(withdraw.bankinformation_id));
  formData.append('bank_information_name', String(withdraw.bank_information_name));
  formData.append('observation_response', String(withdraw.observation_response));
  formData.append('value', String(withdraw.value));

  try {
    await API().post(`/withdrawal/insert_with_file`, formData, config);
  } catch (error) {
    throw error;
  }
};

const createTicketWithdraw = async (withdraw: ReceiptWithdrawRequestInterface) => {
  const config = {
    headers: {
      'Content-Type': `multipart/form-data}`,
    },
  };

  const formData = new FormData();
  formData.append('is_completed', String(withdraw.is_completed));
  formData.append('company_id', String(withdraw.company_id));
  formData.append('company_name', String(withdraw.company_name));
  formData.append('ticket_code', String(withdraw.ticket_code));
  formData.append('observation_response', String(withdraw.observation_response));
  formData.append('value', String(withdraw.value));
  formData.append('beneficiary_name', String(withdraw.beneficiary_name));
  formData.append('beneficiary_document', String(withdraw.beneficiary_document));
  formData.append('bank_name', String(withdraw.bank_name));
  formData.append('assignor_name', String(withdraw.assignor_name));
  formData.append('description', String(withdraw.description));
  formData.append('ticket_settle_date', String(withdraw.ticket_settle_date));
  formData.append('ticket_schedule_date', String(withdraw.ticket_schedule_date));

  try {
    await API().post(`/withdrawal/insert_ticket_with_file`, formData, config);
  } catch (error) {
    throw error;
  }
};
const createPixWithdraw = async (withdraw: WithdrawInterface) => {
  //console.log(withdraw)
  const config = {
    headers: {
      'Content-Type': `multipart/form-data}`,
    },
  };

  const formData = new FormData();
  formData.append('is_completed', String(withdraw.is_completed));
  formData.append('company_id', String(withdraw.company_id));
  formData.append('company_name', String(withdraw.company_name));
  formData.append('pix_code', String(withdraw.pix_code));
  formData.append('pix_type', String(withdraw.pix_type));
  formData.append('observation_response', String(withdraw.observation_response));
  formData.append('value', String(withdraw.value));

  try {
    await API().post(`/withdrawal/insert_pix_with_file`, formData, config);
  } catch (error) {
    throw error;
  }
};
const createClientWithdraw = async (withdraw: WithdrawRequestInterface) => {
  try {
    await API().post(`/ticketpayment/request_ticket_payment`, withdraw, {});
  } catch (error) {
    throw error;
  }
};

const createClientTicketWithdraw = async (withdraw: ReceiptWithdrawRequestInterface) => {
  try {
    await API().post(`/ticketpayment/request_ticket_payment`, withdraw, {});
  } catch (error) {
    throw error;
  }
};

// v2
const createClientPixWithdraw = async (withdraw: WithdrawRequestInterface, company_id?:number) => {
  try {
    await API().post(`/withdrawal/request_pix/${company_id}`, withdraw, {});
  } catch (error) {
    throw error;
  }
};

const getActiveWithdrawals = async (company_id?:number): Promise<WithdrawInterface[]> => {
  try {
    const uri = company_id ? `/withdrawal/listactive/${company_id}` : `/withdrawal/listactive/`;
    const { data } = await API().post(uri, {});
    return data as WithdrawInterface[];
  } catch (error) {
    throw error;
  }
};

const getCertificateWithdrawals = async (withdraw_id:number) => {
  try {
    const { data } = await API().get(`/withdrawal/certificate/${withdraw_id}`);
    return data as WithdrawInterface[];
  } catch (error) {
    throw error;
  }
}



const getWithdrawals = async (is_admin: boolean): Promise<WithdrawInterface[]> => {
  try {
    const uri = is_admin ? `/withdrawal/listall` : `/withdrawal/list`;
    const { data } = await API().post(uri, {});
    return data as WithdrawInterface[];
  } catch (error) {
    throw error;
  }
};

const getWithdrawalsByDate = async (
  is_admin: boolean,
  start_date: string,
  end_date: string,
): Promise<WithdrawInterface[]> => {
  try {
    const uri = is_admin ? `/withdrawal/listall_by_date` : `/withdrawal/list`;
    const jsonData = `{
      "start_date": "${start_date}",
       "end_date": "${end_date}"
    }`;
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    const { data } = await API().post(uri, jsonData, config);
    return data as WithdrawInterface[];
  } catch (error) {
    throw error;
  }
};

const concludeWithdraw = async (withdraw_id: number): Promise<void> => {
  const config = {
    headers: {
      'Content-Type': `multipart/form-data}`,
    },
  };

  try {
    await API().post(`/withdrawal/conclude_with_file/${withdraw_id}`, {}, config);
  } catch (error) {
    throw error;
  }
};

const refuseWithdraw = async (withdraw_id: number): Promise<void> => {
  try {
    await API().post(`/withdrawal/refuse/${withdraw_id}`, {});
  } catch (error) {
    throw error;
  }
};

const downloadWithdrawImages = async (withdraw_id: number): Promise<Blob> => {
  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig;

  try {
    const { data } = await API().get(`/withdrawal/get_compressed_receipts/${withdraw_id}`, config);
    return data as Blob;
  } catch (error) {
    throw error;
  }
};

const getReceiptInfo = async (receipt_id: string): Promise<ReceiptInterface> => {
  try {
    const jsonData = `{"boleto_id": "${receipt_id}"}`;

    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };

    const { data } = await API().post(`/withdrawal/boleto_details`, jsonData, config);
    return data as ReceiptInterface;
  } catch (error) {
    throw error;
  }
};
const getBS2ReceiptInfo = async (receipt_id: string): Promise<BS2ReceiptInterface> => {
  try {
    const jsonData = `{"boleto_id": "${receipt_id}"}`;
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    const { data } = await API().post(`/ticketpayment/consult_ticket`, jsonData, config);
    return data as BS2ReceiptInterface;
  } catch (error) {
    throw error;
  }
};

const getReceiptPaymentStatus = async (
  withdrawal_id: number,
): Promise<{ data_agendamento: string; situacao: string }> => {
  try {
    const { data } = await API().post(`bs2/boleto/payment_status_by_withdrawal_id/${withdrawal_id}`);
    data.data_agendamento = moment(data.data_agendamento).format('DD/MM/YYYY');
    return data as { data_agendamento: string; situacao: string };
  } catch (error) {
    throw error;
  }
};

const getReceiptPaymentScheduleDate = async (withdrawal_id: number): Promise<string> => {
  try {
    const { data } = await API().post(`bs2/boleto/get_receipt_schedule_date/${withdrawal_id}`);
    return data as string;
  } catch (error) {
    throw error;
  }
};

const makeReceiptPayment = async (withdrawal_id: number, operation:string, observation_response:string): Promise<string> => {
  try {
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
      timeout: 30000
    };

    const endpoint = operation === 'BOLETO' ? '/ticketpayment/make_ticket_payment' : '/bs2/pix/make_payment'

    const { data } = await API().post(endpoint, {withdrawal_id: withdrawal_id, observation_response: observation_response}, config);
    return data as string;
  } catch (error) {
    throw error;
  }
};

const getBS2ReceiptDetails = async (withdraw_id: number): Promise<BS2ReceiptDetails> => {
  try {
    const { data } = await API().post(`/bs2/boleto/details_by_withdrawal_id/${withdraw_id}`);
    return data as BS2ReceiptDetails;
  } catch (error) {
    throw error;
  }
};

export const withdrawService = {
  getWithdrawals,
  getWithdrawalsByDate,
  getActiveWithdrawals,
  createWithdraw,
  createTicketWithdraw,
  createClientPixWithdraw,
  createPixWithdraw,
  createClientWithdraw,
  createClientTicketWithdraw,
  refuseWithdraw,
  concludeWithdraw,
  downloadWithdrawImages,
  getReceiptInfo,
  getBS2ReceiptInfo,
  getBS2ReceiptDetails,
  getReceiptPaymentStatus,
  getReceiptPaymentScheduleDate,
  makeReceiptPayment,
  getCertificateWithdrawals
};
