import { Button, Row, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import './styles.css'

interface ModalProps {
  withdraw_id: number;
}

const PixReceiptButton = ({ withdraw_id, theme }: ModalProps & {theme?: string;}) => {

  return (
    <Row justify="space-around" align="middle">
      <Tooltip placement="bottom" title={"Baixar comprovante"} color="#00A19B">
        <Button 
            type="ghost" 
            shape="circle" 
            icon={<DownloadOutlined style={{fontSize: 25}}/>} 
            size="large" 
            className={`pix-table-upload ${theme === "dark" && 'pix-table-upload-dark'}`}
            onClick={() => window.open(`https://sistema.tamborete.com.br/api/withdrawal/certificate/${withdraw_id}`)}
        />
      </Tooltip>
    </Row>
  );
};

export default PixReceiptButton;
