import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'antd';
import { taxActions } from '../../../actions/tax';
import { TaxGroupInterface } from '../../../types/tax_group.type';

import PageHeader from '../../../components/Layout/PageHeader';

import './styles.css';
import InputNumber from '../../../components/Inputs/Number';
import { UserInterface } from '../../../types/user.type';

interface RootState {
  tax: TaxGroupInterface;
  authentication: {
    user: UserInterface;
  };
}

const mapState = (state: RootState) => ({
  user: state.authentication.user,
  tax: state.tax,
});

const actionCreators = {
  loadTaxes: taxActions.loadTaxes,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

const TaxSimulator = ({ tax, user, loadTaxes }: PropsFromRedux) => {

  useEffect(() => {
    if (user.role !== "admin" && user.companies) {
      const company_id = Number(user.companies[0].id)
      loadTaxes(company_id);
    }
  }, []);

  const [transactionType, setTransactionType] = useState<string>("receipt")
  const [returnType, setReturnType] = useState<number>(0.0)
  const [simulatorValue, setSimulatorValue] = useState<string>("1000.00")

  const return_value = [
    {label: "R0", value: 0.00},
    {label: "R2", value: 0.02},
    {label: "R5", value: 0.05},
    {label: "R8", value: 0.08},
    {label: "R10", value: 0.10},
    {label: "R13", value: 0.13},
    {label: "R16", value: 0.16},
    {label: "R20", value: 0.20},
  ]

  const handleFormatCurrency = () => {
    setSimulatorValue(Number(simulatorValue).toFixed(2))
  }

  const printMoneyValue = (value: number) => {
    return value.toLocaleString("pt-br", {style: "currency", currency: "BRL"})
  }

  return (
    <>
      <PageHeader title={'Simulação de vendas'} />
      <div className='simulator-container'>
        <div className='simulator-header'>
          <span className='simulator-header-title'>
            Descubra quanto você irá receber na sua venda com a <b style={{color: "#0A1E41"}}> Tamborete Pay!</b>
          </span>
          <span>
            Com a 
            <b style={{color: "#0A1E41"}}> Tamborete Pay</b>, você recebe em 
            <b> 30 minutos </b>cobrando o valor de:
          </span>
        </div>
        <div className='simulator-content'>
          <div className='simulator-menu'>
            <div className='simulator-menu-item'>
              <span>Tipo de transação</span>
              <div className='simulator-menu-item-button-group'>
                <Button 
                  onClick={() => setTransactionType("receipt")}
                  className={`simulator-menu-item-button ${transactionType === "receipt" && 'simulator-menu-item-button-active'}`}
                >
                  Valor a receber
                </Button>
                <Button
                  onClick={() => setTransactionType("charge")}
                  className={`simulator-menu-item-button ${transactionType === "charge" && 'simulator-menu-item-button-active'}`}
                >
                  Valor a cobrar
                </Button>
              </div>
            </div>
            <div className='simulator-menu-item'>
              <span>Retorno</span>
              <div className='simulator-menu-item-multiple-buttons'>
                {return_value.map((item, index) => {
                  return (
                    <Button 
                      key={index}
                      onClick={() => setReturnType(item.value)}
                      className={`simulator-menu-item-button ${returnType === item.value && 'simulator-menu-item-button-active'}`}
                    >
                      {item.label}
                    </Button>
                  )
                })}
              </div>
            </div>
            <div className='simulator-menu-item'>
              <span>Valor</span>
              <InputNumber
                  kind="primary"
                  prefix="R$"
                  placeholder='1.000,00'
                  name="value"
                  value={simulatorValue}
                  onChange={(event) => setSimulatorValue(event.target.value)}
                  onBlur={handleFormatCurrency}
              />
            </div>
          </div>
          <div className='simulator-result'>
            <span>
              Simulação feita utilizando o tipo de transação 
              <b style={{color: "#00A19B"}}> {transactionType === 'receipt' ? 'Valor a receber': 'Valor a cobrar'} </b>, com o retorno
              <b style={{color: "#00A19B"}}> {return_value.filter(item => item.value === returnType)[0].label} </b>e o valor de 
              <b style={{color: "#00A19B"}}> {Number(simulatorValue).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</b>.
            </span>
            <div className='simulator-result-content'>
              {tax.taxes?.sort((a,b) => Number(a.installments) - Number(b.installments)).map((item) => {
                if (Number(item.installments) >= 0) {

                  const value = Number(simulatorValue)
                  const tax = Number(item.value) / 100
                  const final = transactionType === "receipt" ? (value / (1 - tax)) * (1 + returnType) : value * (1 - tax)

                  return (
                    <div key={item.installments}>
                      <div className="simulator-result-content-item">
                        <div className='simulator-result-content-describe'>
                          <span className='simulator-result-content-describe-title'>{Number(item.installments) === 0 ? "débito" : item.installments} {Number(item.installments) === 0 ? undefined : Number(item.installments) > 1 ? 'parcelas': 'parcela'}</span>
                          <span style={{fontSize: 27, display: "flex"}}>
                            R$
                            <b>{item.installments === 0 ? printMoneyValue(final).split(',')[0].split('R$')[1] : printMoneyValue(final / Number(item.installments)).split(',')[0].split('R$')[1]}</b>
                            <span style={{fontSize: 14, lineHeight: "36px"}}>
                              ,{item.installments === 0 ? printMoneyValue(final).split(',')[1] : printMoneyValue(final / Number(item.installments)).split(',')[1]}
                            </span>
                          </span>
                        </div>
                        <div className='simulator-result-content-describe'>
                          <span>{transactionType !== 'receipt' ? "Valor líquido" : "Valor total"}</span>
                          <b>{printMoneyValue(final)}</b>
                        </div>
                      </div>
                      <div className='simulator-result-divider'/>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connector(TaxSimulator);
