import { AxiosRequestConfig } from 'axios';
import API from '../factory/api';

import { PaymentsLink } from '../types/payments_link';
import moment from 'moment';
import { TransationCard } from '../types/transation_card';

const createLink = async (dataLink: PaymentsLink, companyId: number) : Promise<string>=> {
  try {
    return (await API().post(`/paymentlink/insert/${companyId}`, {amount: dataLink.amount, name: dataLink.name,description: dataLink.description, installmentsLimit: dataLink.installmentsLimit })).data;
  } catch (error) {
    throw error;
  }
};

const transation = async (transation: TransationCard) : Promise<boolean>=> {

  try {
    return (await API().post(`/paymentlink/transation`, {amount: transation.amount, installments:transation.installments, cardNumber: transation.cardNumber, cardExpirationDate: transation.cardExpirationDate, cardHolderName: transation.cardHolderName, cardCvv: transation.cardCvv,email:transation.email,nome:transation.nome,document:transation.document,idLink:transation.idLink })).data;
  } catch (error) {
    throw error;
  }
};

const getLinks = async (companyId:number): Promise<PaymentsLink[]> => {
  try {
    const { data } = await API().post(`paymentlink/list_paged`, {company_id: companyId, page: 1, per_page: 1000});
    return data as PaymentsLink[];
  } catch (error) {
    throw error;
  }
};
const getLink = async (linkId: string): Promise<PaymentsLink> => {
  try {
    const { data } = await API().get(`paymentlink/link/`+linkId,);
    return data as PaymentsLink;
  } catch (error) {
    throw error;
  }
};

export const paymentslinkService = {
    getLinks,
    getLink,
    createLink,
    transation
};
