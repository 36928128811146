import { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Tabs } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { TransactionsInterfacePaged } from '../../../types/transactions.type';
import { companiesActions } from '../../../actions/companies';
import { UserInterface } from '../../../types/user.type';
import PageHeader from '../../../components/Layout/PageHeader';
import TransactionsTable from '../../../components/TransactionsTable';
import {ReactComponent as FilterIcon} from '../../../static/icon/filter-icon.svg';
import {ReactComponent as SearchIcon} from '../../../static/icon/search.svg';
import './styles.css';
import { BalanceHistoryInterfacePaged } from '../../../types/balance_history.type';
import BalanceHistoryTable from '../../../components/BalanceHistoryTable';
import { CompaniesService } from '../../../services/company.service';
import { TransactionsHeadersInterface } from '../../../types/companies.type';
import DataSelector from '../../../components/DataSelector';

interface RootState {
  transactions: {
    card: TransactionsInterfacePaged,
  };
  authentication: {
    user: UserInterface;
  }
}

const mapState = (state: RootState) => ({
  transactions: state.transactions.card,
  user: state.authentication.user
});

const actionCreators = {
  loadTransactions: companiesActions.loadTransactions,
};
const connector = connect(mapState, actionCreators);

const periods = [
  { id: 15, days: 15 },
  { id: 30, days: 30 },
  { id: 45, days: 45 },
  { id: 60, days: 60 }
];

type PropsFromRedux = ConnectedProps<typeof connector>;

const { TabPane } = Tabs;

const TransactionsPage = ({ 
  user, 
}: PropsFromRedux) => {

  const [transactionsData, setTransactionsData] = useState<TransactionsInterfacePaged>(
    {
      data: [],
      pagination: {
        page: 0,
        per_page: 0,
        pages: 0
      }
    }
  );
  const [transactionHeader, setTransactionHeader] = useState<TransactionsHeadersInterface>({
    total_sales_value: 0,
    total_sales: 0,
    total_failed_transactions: 0,
    average_ticket: 0,
    chargeback: 0
  });

  const [selectedPeriodId, setSelectedPeriod] = useState<number>();
  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [priceValue, setInputValue] = useState<string>('');
  const [pix_deposit, setPixDeposit] = useState<BalanceHistoryInterfacePaged>();
  const [activeTabKey, setActiveTabKey] = useState<string>('card');

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Pattern allows numbers and optionally a single comma
    const pattern = /^[0-9]*,?[0-9]*$/;
    if (pattern.test(value) || value === '') {
      setInputValue(value);
    }
  };

  const getTransactionsHeader = async (company_id:number) => {
    const transactionHeader = await CompaniesService.loadTransactionsHeaders(company_id);
    setTransactionHeader(transactionHeader);
  }

  const getTransactionsFiltered = async (
    company_id: number, 
    page: number, 
    startDate?: string, 
    endDate?: string, 
    selectedPeriodId?: number, 
    value?: string, 
    transactionType?: string
  ) => {
    const transactions = await CompaniesService.loadTransactionsFilteredPaged(
      company_id, 
      page, 
      startDate, 
      endDate, 
      selectedPeriodId, 
      value, 
      transactionType
    );
    setTransactionsData(transactions);
  }

  const getPixDepositHist = async (company_id: number, page: number) => {
    const pix_deposit = await CompaniesService.getPixDepositPaged(company_id, page);
    setPixDeposit(pix_deposit);
  }

  const handleFilter = () => {
  
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      getTransactionsFiltered(company_id, currentPage, startDate, endDate, selectedPeriodId, priceValue, 'card');
    }
    
    setFilterModalVisible(false);
  }

  const changePage = (page: number) => {
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      getTransactionsFiltered(company_id, page, startDate, endDate, selectedPeriodId, undefined, 'card');
      setCurrentPage(page);
    }
  }

  const pixDepositChangePage = (page: number) => {
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      getPixDepositHist(company_id, page);
    }
  }

  const printMoneyValue = (value: number) => {
    return value.toLocaleString("pt-br", {style: "currency", currency: "BRL"})
  }

  useEffect(() => {
    if (user.role === "admin") {
      console.log("admin")
    } else if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      //getTransactions(1);
      getTransactionsFiltered(company_id, 1, undefined, undefined, undefined, undefined, 'card');
      getTransactionsHeader(company_id);
      getPixDepositHist(company_id, 1);
    }
  }, []);

  return (
    <>
      <PageHeader title="Relatório de vendas" />
      <div className='transactions-filter-content'
      >
      { activeTabKey === 'card' &&
      <Button className='transactions-button'  
        style={{display: 'flex', justifyItems: 'center', alignItems: 'center'}}
        onClick={() => setFilterModalVisible(!filterModalVisible)}
        type={filterModalVisible ? 'primary' : 'default'}
      >
        <FilterIcon/>
      </Button>
      }
        {filterModalVisible &&
          (
            <div className='transactions-filter-menu'>
              <span><strong>Buscar por valor</strong></span>
              <Input 
                placeholder='Pesquisar por valor...'
                prefix={<span style={{color:'#00A19B'}}>R$</span>}
                suffix={<SearchIcon/>}
                style={{borderRadius: '5px', borderBottom: '2px solid #00A19B'}}
                value={priceValue}
                onChange={handleChange}
              />
              <span><strong>Buscar por data</strong></span>
              <DataSelector 
                calendarIcon={false}
                dataPickerHeight='32px'
                dataSelectorBackgroundColor='white'
                initialStartDate={startDate}
                initialEndDate={endDate}
                onStartDateChange={(date) => {
                  setStartDate(date);
                  setSelectedPeriod(undefined); // Desmarca o período selecionado ao mudar a data de início
                }}
                onEndDateChange={(date) => {
                  setEndDate(date);
                  setSelectedPeriod(undefined); // Desmarca o período selecionado ao mudar a data de término
                }}
                arrowIcon={false}
              />
                <div className='checkbox-filter-content' style={{
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  marginLeft: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  alignItems: 'start', // Mudado de alignContent para alignItems para um alinhamento mais efetivo
                  justifyContent: 'center'
                }}>
                  {periods.map(period => (
                    <div key={period.id} className="custom-checkbox-wrapper">
                      <Checkbox
                          
                          checked={selectedPeriodId === period.id}
                          onChange={() => {
                            if (selectedPeriodId === period.id) {
                              setSelectedPeriod(undefined); // Limpa a seleção se o mesmo período for selecionado novamente
                            } else {
                              setSelectedPeriod(period.id); // Atualiza para o novo período selecionado
                              setStartDate(undefined);
                              setEndDate(undefined);
                            }
                          }}

                        > <strong>últimos {period.days} dias</strong> </Checkbox>
                    </div>
                  ))}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button 
                  style={{width:'70%', backgroundColor: '#0A1E41', color:'#FFFFFF'}}
                  onClick={handleFilter}
                  size='large'
                >
                  Aplicar filtro
                </Button>
                </div>
            </div>
          )
        }
      </div>
      <div className='home-card-group'>
        <div className='home-card-item'>
          <div className='home-card-describe-content'>
            <span className='home-card-title'>Vendas aprovadas</span>
            <span className='home-card-describe'>{
              transactionHeader ? printMoneyValue(Number(transactionHeader.total_sales_value)) : printMoneyValue(0)}
            </span>
            <span>
              {Number(transactionHeader.total_sales)} vendas
            </span>
          </div>
        </div>
        { false &&
        <div className='home-card-item'>
          <div className='home-card-describe-content'>
            <span className='home-card-title'>Falhas e Cancelamentos</span>
            <span className='home-card-describe'>{transactionHeader.total_failed_transactions}</span>
          </div>
        </div>
        }
        <div className='home-card-item'>
          <div className='home-card-describe-content'>
            <span className='home-card-title'>Ticket médio</span>
            <span className='home-card-describe'>{
              transactionHeader ? printMoneyValue(Number(transactionHeader.average_ticket)) : printMoneyValue(0)}
            </span>
          </div>
        </div>
      </div>
      <Tabs onChange={handleTabChange} activeKey={activeTabKey}>
        <TabPane tab="Cartão" key="card">
          <div className='transactions-table-content'>
            <TransactionsTable 
              transactions={transactionsData.data} 
              pagination={transactionsData.pagination} 
              changePage={changePage}
            />
          </div>
        </TabPane>
        { pix_deposit &&
        <TabPane tab="Deposito Pix" key="pix">
          <div className='transactions-table-content'>
            <BalanceHistoryTable 
              balance_history={pix_deposit.data}
              pagination={pix_deposit.pagination}
              changePage={pixDepositChangePage}
            />
          </div>
        </TabPane>
        }
      </Tabs>
    </>
  );
};

export default connector(TransactionsPage);
