import React, { useState } from 'react';
import { WithdrawInterface } from '../../types/withdraw.type';
import { connect, ConnectedProps } from 'react-redux';
import { formatDate } from '../../helpers/utils';

import { Button, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import PaymentsModal from '../PaymentsModal';
import PixReceiptButton from '../ReceiptButton';

import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import {ReactComponent as CalendarIcon} from '../../static/icon/calendar-green.svg';
import {ReactComponent as ProfileIcon} from '../../static/icon/profile-green.svg';
import {ReactComponent as MoneyIcon} from '../../static/icon/money-green.svg';
import {ReactComponent as DescriptionIcon} from '../../static/icon/description-green.svg';

import './styles.css'
import { ClientInterface } from '../../types/client.type';
import { PaymentsLink } from '../../types/payments_link';
import moment from 'moment';

interface RootState {
    user: {
        info: ClientInterface;
    };
}

interface LinkPaymentsTableProps {
    links: PaymentsLink[]
}

const mapState = (state: RootState) => ({
    user: state.user.info,
});


const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector> & LinkPaymentsTableProps;

const LinkPayments = ({
    user,
    links
}:PropsFromRedux ) => {

    const [openModal, setOpenModal] = useState<{open: boolean; type: string, typeOfWithdraw:string, withdraw?: WithdrawInterface}>({open: false, typeOfWithdraw: "BOLETO", type: ""})
    const { innerWidth: width } = window;

    const columns:ColumnsType<PaymentsLink> = [
        {
            key: "created_at",
            dataIndex: "created_at",
            width: 300,
            render: (record:number) => {
                return (
                    <Col className='bank-split-payments-cell'>
                        <Row align='middle'>
                            <CalendarIcon />
                            <span className='bank-split-payments-title'>Data</span>
                        </Row>
                        <Row style={{width: 200}}>
                            <span className='bank-split-payments-value'>{moment.utc(record).format('DD/MM/YYYY HH[h]mm[min]')}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "name",
            dataIndex: "name",
            width: 250,
            render: (record:string) => {
                return (
                    <Col className='bank-split-payments-cell'>
                        <Row align='middle'>
                            <ProfileIcon />
                            <span className='bank-split-payments-title'>Nome do link</span>
                        </Row>
                        <Row style={{
                            maxWidth: width <= 991 ? 100 : "",
                            whiteSpace: "nowrap"
                        }}>
                            <span className='bank-split-payments-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "amount",
            dataIndex: "amount",
            width: 200,
            render: (record:string) => {
                return (
                    <Col className='bank-split-payments-cell'>
                        <Row align='middle'>
                            <MoneyIcon />
                            <span className='bank-split-payments-title'>Valor</span>
                        </Row>
                        <Row >
                            <span className='bank-split-payments-value'>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "url",
            dataIndex: "url",
            width: 500,
            render: (record:string) => {
                return (
                    <Col className='bank-split-payments-cell last-cell'>
                        <Row align='middle'>
                            <DescriptionIcon />
                            <span className='bank-split-payments-title'>Link</span>
                        </Row>
                        <Row style={{
                            maxWidth: width <= 991 ? 200 : "",
                            whiteSpace: "nowrap"
                        }}>
                              <a href={record} target="_blank" rel="noopener noreferrer">{record}</a> 
                        </Row>
                    </Col>
                )
            }
        },
    ]

    return (
        <>       
            <Table
                rowKey="id"
                pagination={false}
                showHeader={false}
                dataSource={links} 
                columns={columns}
                size="middle"
                className="bank-split-payments"
                expandable={{
                    expandIcon: ({expanded, onExpand, record}) => {
                        return (
                            <Col className='bank-split-payments-cell first-cell' style={{justifyContent: "center"}}>
                                {expanded ? (
                                    <MinusSquareOutlined style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)}/>
                                ) : (
                                    <PlusSquareOutlined  style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)} />
                                )}
                            </Col>
                        )
                    },
                    expandedRowRender: (record) => {
                        return (
                        <>
                            <Col xs={24} sm={24} xl={24}>
                            <Row className='bank-split-payments-description'>
                                <Col xs={10} sm={10} xl={10}>
                                <Row align='middle'>
                                    <Col>
                                    <h3 className='bank-split-payments-description-title'>Informação de pagamento</h3>
                                    </Col>
                                </Row>
                                </Col>
                                <Col xs={7} sm={7} xl={7} className="bank-split-payments-status">
                                <span className='bank-split-payments-description-value'>Status do pagamento: <span className='value' style={{color: "#FFC164"}}>{record.status == 1?"Pendente de pagamento":"concluído"}</span></span>
                                </Col>
                                <Col xs={7} sm={7} xl={7} className="bank-split-payments-actions">
                                <Row align='middle'>
                                  
                                </Row>
                                </Col>
                            </Row>
                            </Col>
                        </>
                        )
                    } 
                }}
            />
        </>

    )
}
export default connector(LinkPayments);