import React, { useState, useEffect } from "react";
import { TaxGroupInterface } from "../../types/tax_group.type";
import { taxService } from "../../services/tax.service";
import { CompaniesService } from '../../services/company.service';
import { Button, InputNumber, message, Modal } from 'antd';

import InputSelect from "../Inputs/Select";

import Logo from '../../static/tamborete-pay-modal.png';

import './styles.css'

interface ChangeDelayModalProps {
  open: boolean;
  company_id: number;
  delay?: number;
  onClose: () => void;
}

const ChangeDelayModal = ({
  open,
  company_id,
  onClose
}: ChangeDelayModalProps) => {

  const [loadingActive, setLoadingActive] = useState<boolean>(false)
  const [delay, setDelay] = useState<number>(0)
 
  const handleSetCompanyDelay = async () => {
    setLoadingActive(true)
    try {
      //const response = await taxService.associateTaxGroupUser(company_id, forms.tax_group_id);
      const response = await CompaniesService.setCompanyDelay(company_id, delay);
      if(response) {
        message.success(`Grupo de taxa alterado com sucesso`);
        window.location.reload()
      } else {
        setLoadingActive(false)
        message.error(`Falha ao alterar grupo de taxa`);
      }
    } catch (error) {
      setLoadingActive(false)
      message.error(`Falha ao alterar grupo de taxa`);
    }
  }

  const ModalTitle = () => {
    return (
        <div className='delay-modal-title-container'>
            <img src={Logo} alt="" />
        </div>
    )
  }

  const ModalFooter = () => {
    return (
        <div className='delay-modal-footer-content'>
            <Button className='delay-modal-button-reprove' onClick={onClose}>Cancelar</Button>
            <Button className='delay-modal-button-approve' loading={loadingActive} onClick={handleSetCompanyDelay}>Alterar</Button>
        </div>
    )
  }


  return (
    <Modal
      className="delay-modal"
      title={<ModalTitle />}
      footer={<ModalFooter />}
      onCancel={onClose}
      width={400}
      open={open}
    >
      <span className="delay-modal-body-title">Defina os dias para recebimento</span>
      <div className="delay-modal-content">
        <span>Selecione grupo de taxa:</span>
        <InputNumber
            className="delay-modal-input"
            placeholder="Dias"
            min={0}
            max={30}
            prefix="D-"
            onChange={(value) => setDelay(value ? Number(value) : 0)}
        >
        </InputNumber>
      </div>
    </Modal>
  )
}

export default ChangeDelayModal;